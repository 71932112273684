import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   component: () => import("@/layout/index.vue"),
  //   redirect: "/index",
  //   children: [
  //     // 需要layout的页面
  //     {
  //       path: "index",
  //       name: "index",
  //       meta: {
  //         title: "index",
  //       },
  //       component: () => import("@/pages/index.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: () => import("@/pages/index.vue"),
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "index",
        meta: {
          title: "index",
        },
        component: () => import("@/pages/index.vue"),
      },
    ],
  },
  // 提交页
  {
    path: "/submit",
    component: () => import("@/pages/submit.vue"),
  },
  // 不需要layout的页面
  // 替代vue2中的'*'通配符路径
  { path: "/:pathMatch(.*)*", redirect: "/" },
  // 个人信息共享授权协议
  {
    path: "/personal",
    component: () => import("@/pages/protocol/personal.vue"),
  },
  // 用户隐私政策
  {
    path: "/privacy",
    component: () => import("@/pages/protocol/privacy.vue"),
  },
  // 用户注册协议
  {
    path: "/userService",
    component: () => import("@/pages/protocol/userService.vue"),
  },
  // 个人信息共享授权协议
  {
    path: "/app/personal",
    component: () => import("@/pages/protocol/personal-app.vue"),
  },
  // 用户隐私政策
  {
    path: "/app/privacy",
    component: () => import("@/pages/protocol/privacy-app.vue"),
  },
  // 用户注册协议
  {
    path: "/app/userService",
    component: () => import("@/pages/protocol/userService-app.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(), // history 模式则使用 createWebHistory()
  routes,
});
export default router;
